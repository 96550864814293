<template>
    <el-dialog
        v-if="showPopupMid && !isOneTapDisplayed && isMobileOrTablet"
        :visible.sync="centerDialogVisible"
        :show-close="false"
        :close-on-click-modal="false"
        center
        custom-class="popup-remind-login"
        @close="closeDialog"
    >
        <div class="d-flex flex-column justify-content-center position-relative h-100 w-100">
            <i
                class="fa fa-times fa-lg w-100 position-absolute icon-multiply cursor-pointer"
                aria-hidden="true"
                @click="closePopup"
            />
            <div class="button proflile-login">
                <div class="d-flex popup-title">
                    <p class="title-notification">
                        {{ $t('PopupDownloadApp.title') }}
                    </p>
                </div>
                <p class="text-description">
                    {{ $t('PopupDownloadApp.content') }}
                </p>
                <a
                    href="https://onelink.to/rs5k8f"
                    target="_blank"
                    class="reminder-button-login"
                >
                    {{ $t('PopupDownloadApp.download') }}
                </a>
                <div class="bg-image-botton w-100 d-flex justify-content-center">
                    <div class="image-container mt-3">
                        <img
                            src="/images/image-download-app.png"
                            alt="Download App"
                            class="image-popup"
                        >
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        data() {
            return {
                centerDialogVisible: true,
                firstScroll: true,
                isMobileOrTablet: false,
            }
        },

        computed: {
            ...mapState('auth', { showPopupMid: 'reminderLogin', avtUser: 'avtUser' }),
            ...mapState('oneTap', { isOneTapDisplayed: 'isDisplayed' }),

            editProfile() {
                return `${process.env.SSO_APP_URL}/profile/personal`
            },

            imagePopup() {
                const avtUser = this.$store.state.auth.avtUser

                return avtUser ? `${process.env.SSO_IMAGES_URL}/400x400/${avtUser}` : '/logo_full.svg'
            },
        },

        watch: {
            $route() {
                this.centerDialogVisible = true
                this.firstScroll = true

                setTimeout(() => {
                    const heightBrowser = window.innerHeight
                    const heightPage = document.body.clientHeight
                    const thereIsScroll = (heightPage - heightBrowser < 250)

                    if (thereIsScroll) {
                        this.$store.commit('auth/setReminderLogin', true)
                    } else {
                        this.handleSCroll()
                    }
                }, 5000)
            },
        },

        mounted() {
            this.checkScreenSize()
            window.addEventListener('resize', this.checkScreenSize)
            window.addEventListener('scroll', this.handleSCroll)
        },

        destroyed() {
            window.removeEventListener('resize', this.checkScreenSize)
            window.removeEventListener('scroll', this.handleSCroll)
        },

        methods: {
            ...mapActions('auth', ['showAuthDialog']),

            closeDialog() {
                this.$store.commit('auth/setReminderLogin', false)
            },

            closePopup() {
                this.centerDialogVisible = false
            },

            goToRegister() {
                window.location.href = `${process.env.SSO_APP_URL}/register`
            },

            goToFogotPasssword() {
                window.location.href = `${process.env.SSO_APP_URL}/forgot-password`
            },

            handleSCroll() {
                if (window.scrollY > 250 && this.firstScroll) {
                    this.$store.commit('auth/setReminderLogin', true)
                    this.firstScroll = false
                }
            },

            checkScreenSize() {
                this.isMobileOrTablet = window.innerWidth <= 1024
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";

    .el-dialog__wrapper {
        .popup-remind-login {
            position: fixed;
            margin: auto;
            left: 50%;
            bottom: 50%;
            transform: translate(-50%, 50%);
            width: 30vw;
            height: 500px;
            overflow: hidden;
            .el-dialog__header {
                display: none;
            }
            .el-dialog__body {
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .icon-multiply {
                top: 0;
                left: 98%;
            }
        }
    }
    .image-container {
        max-width: 300px;
        height: 300px;
        overflow: hidden;
    }
    .image-container img {
        width: 100%;
        height: auto;
        display: block;
    }

    .bg-image-botton {
        background-image: url('/images/bg-pop-up-download-app.png');
        background-repeat: no-repeat;
        background-position: center;
    }

    .proflile-login {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title-notification {
        font-weight: 600;
        color: #050505;
        font-size: 32px;
        margin: 0;
    }

    .text-description {
        font-weight: 400;
        color: #050505;
        font-size: 22px;
    }

    .image-avt {
        width: 400px;
        height: auto;
    }

    .image-popup {
        width: 340px;
        height: auto;
    }

    .border-image-logo {
        flex-grow: 2;
        display: flex;
        justify-content: center;
    }

    .reminder-button-login {
        width: 80%;
        background-color: #457CE8;
        font-weight: 600;
        font-size: 17px;
        border-color: #ffffff;
        color: white;
        padding: 10px 0;
        border-radius: 8px;
        border: none;
        max-width: 20rem;
        display: flex;
        justify-content: center;
    }

    .reminder-button-login:hover {
        background-color: #3a6cb8;
        color: white;
        text-decoration: none;
    }

    @media only screen and (min-width: 1000px) {
        .el-dialog--center {
            min-width: 900px;
        }
    }

    @media only screen and (max-width: 1000px) {
        .el-dialog--center {
            min-width: 600px;
        }

        .title-notification {
            font-weight: 600;
            color: #050505;
            font-size: 26px;
        }

        .proflile-login {
            margin-left: 0;
        }

        .popup-title {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    @media only screen and (max-width: 600px) {
        .el-dialog--center {
            min-width: 350px;
        }
        .title-notification {
            font-size: 22px;
        }
        .text-description {
            font-size: 13px;
        }
    }
</style>
